import axios from 'axios';
import React, { useEffect, useState, useContext, useRef } from 'react'
import moment from 'moment-timezone';

// import ReactQuill from "react-quill";
// import "react-quill/dist/quill.snow.css";

import { AuthContext } from '../context/authContext';

import jwt_decode from "jwt-decode"
import { Scrollbars } from 'react-custom-scrollbars-2';

import { Editor } from '@tinymce/tinymce-react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { proxy } = require('../../package.json')

axios.defaults.baseURL = proxy

const Home = () => {

  const [initialText, setInitialText] = useState('');
  const [text, setText] = useState(initialText);

  const [categories, setCategories] = useState([])
  const [notes, setNotes] = useState([])
  const [note, setNote] = useState({});
  const [activeCategory, setActiveCategory] = useState(1);
  const [search, setSearch] = useState("");
  const [title, setTitle] = useState("");
  var [category, setCategory] = useState(1);
  const [activeEditor, setActiveEditor] = useState(0);
  const [activeNote, setActiveNote] = useState(null);
  const [saved, setSaved] = useState(0);

  const [loadingNotes, setLoadingNotes] = useState(0);
  const [loadingNote, setLoadingNote] = useState(0);
  const [loadingButton, setLoadingButton] = useState(0);

  const onFocus = () => {
    setActiveEditor(1)
    setSaved(0)
  }

  moment.tz.setDefault("Etc/GMT+0");

  const { currentUser } = useContext(AuthContext)

  axios.interceptors.request.use(
    async (config) => {
      config.headers["authorization"] = "Bearer " + currentUser;
      config.headers["x-id"] = jwt_decode(currentUser).id;
      config.headers["x-hash"] = jwt_decode(currentUser).hash;
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  useEffect(() => {
    setSaved(0)
  }, [title, text]);

  useEffect(() => {

    const appHeight = () => {
        const doc = document.documentElement
        doc.style.setProperty('--app-height', `${window.innerHeight}px`)
    }
    window.addEventListener('resize', appHeight)

    appHeight()

    ///

    // const fetchCategories = async () => {
    //   try {
    //     const res = await axios.get(`/categories`)
    //     setCategories(res.data)
    //   } catch (err) {
    //     console.log(err)
    //   }
    // }

    // fetchCategories();

    setCategories([{"id":1,"title":"Temporary","icon":"temp.svg"},{"id":2,"title":"Important","icon":"important.svg"},{"id":3,"title":"Trash","icon":"trash.svg"}])

    ///

    const fetchNotes = async () => {

      try {
        const res = await axios.get(`/notes`)
        setNotes(res.data)
      } catch (err) {
        console.log(err)
      }

    }

    fetchNotes();

  }, []);

  const fetchNotesByCategory = async (activeCategory) => {

    try {

      setLoadingNotes(1)
      setActiveEditor(0)
    
      const res = await axios.get(`/notes/?category_id=${activeCategory}`)
      setNotes(res.data)

      setLoadingNotes(0)
      
    } catch (err) {
      console.log(err);
    }

  }

  useEffect(()=>{
    if(activeCategory !== "") {
      const fetchData = async () => {
        fetchNotesByCategory(activeCategory);
      }
      fetchData();
    }
  }, [activeCategory])

  useEffect(() => {

    if(search === "") {
      newNote()
      if(activeCategory === 1) {
        fetchNotesByCategory(1); 
      } else {
        setActiveCategory(1)
      }
    }

    const delayDebounceFn = setTimeout(async () => {

      setActiveEditor(0)

      try {

        setActiveCategory("")
        newNote()

        if(search !== "") {
          setLoadingNotes(1)
          const res = await axios.post(`/notes/search/?search=${search}`)

          setNotes(res.data)
          setLoadingNotes(0)
          
        } else {
          setActiveCategory(1)
        }

      } catch (err) {
        console.log(err)
      }

    }, 800)

    return () => clearTimeout(delayDebounceFn)
    // eslint-disable-next-line
  }, [search])

  const getText = (html, symbols) =>{
    const doc = new DOMParser().parseFromString(html, "text/html")
    const text = doc.body.textContent
    var sep = ""

    if(text.length > symbols) {
      sep = ".."
    }
    return doc.body.textContent.substring(0, symbols) + sep;
  }

  const getNote = async (key) => {

    try {
      setLoadingNote(1)
      setSaved(0)
      setTitle("")
      setInitialText("")
      setActiveNote(key)
      setActiveEditor(1);
      const res = await axios.get(`/notes/${key}`);
      setNote(res.data);
      setLoadingNote(0)
    } catch (err) {
      console.log(err);
    }

  };

  useEffect(() => {

    if(note.title !== undefined && note.text !== undefined && note.category_id !== undefined) {
      setTitle(note.title);
      setInitialText(note.text);
      setCategory(note.category_id);
    }

  }, [note]);

  const handleClick = async (knownCategory = undefined) => {

    try {

      var dbTitle = title

      if(text !== "") {

        setLoadingButton(1)

        if(dbTitle === "") {
          dbTitle = 'Untitled note'
        }

        if(knownCategory !== undefined) {
          category = knownCategory
        }

        const dbObject = {
          title: dbTitle,
          text: text,
          category_id: category,
          date: moment(moment()).format("YYYY-MM-DD HH:mm:ss"),
        }

        if (Object.keys(note).length === 0) {
          const res = await axios.post(`/notes`, dbObject); // add note
          getNote(res.data)
          setActiveEditor(1)
          setActiveNote(res.data)
        } else {
          await axios.put(`/notes/${note.id}`, dbObject); // update note
        }

        if(category === activeCategory || knownCategory !== undefined) {
          
          fetchNotesByCategory(activeCategory);

          if(knownCategory !== undefined) {
            newNote()
          }

        } else {
          setActiveCategory(category);
        }

        setLoadingButton(0)
        setSaved(1)

        if(knownCategory !== undefined) {
          setActiveEditor(0)
        }

      }

    } catch (err) {
      console.log(err);
    }

  };

  const deleteNote = async (e) => {

    e.preventDefault();

    try {
      handleClick(3) // to trash
    } catch (err) {
      console.log(err);
    }

  };

  const newNote = async (openEditor = undefined) => {

    try {
      setNote({})
      setTitle("")
      setInitialText("")
      setCategory(1)
      setActiveNote()
      setSaved(0)
    } catch (err) {
      console.log(err);
    }

    if(openEditor !== undefined) {
      setActiveEditor(1)
    }

  };

  const filterByCategory = async (key) => {
    setActiveCategory(key);
    newNote()
    // setActiveEditor(0)
  };

  // const modules = {
  //   toolbar: [[{ 'header': [2, 3, false] }], ['bold', 'italic', 'underline', 'strike'], ['blockquote', 'code-block', 'link', { 'list': 'ordered'}, { 'list': 'bullet' }], ['clean']]
  // }

  const editorRef = useRef(null);

  // Define the React function to be called on button click
  const copyToClipboard = (event) => {
    navigator.clipboard.writeText(event.target.innerText);
    toast('Copied to clipboard');
  };

  useEffect(() => {

    window.copyToClipboard = copyToClipboard;

    // Cleanup function to remove the global reference
    return () => {
      delete window.copyToClipboard;
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
    <ToastContainer
    position="bottom-right"
    autoClose={1500}
    hideProgressBar={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable={false}
    pauseOnHover
    theme="dark"
    />
    <div id="sidebar">


        {categories && categories.length ? (
          
          <ul>
              {categories.map((category, index) =>(
                <li key={category.id} className={activeCategory === category.id ? "m-active" : ""} onClick={ event => filterByCategory(category.id) }><button><span style={{ backgroundImage: `url(../img/${category.icon})` }}></span><small>{category.title}</small></button></li>
              ))}
          </ul>

        ) : (
          <span></span>
        )}
    </div>
    <div id="main">
        <div id="header">
            <div className="header__search">
                <input type="text" placeholder="Search" autoComplete="off" onChange={(e) => setSearch(e.target.value)} />
            </div>
            <div className="header__buttons">
                <button className={`m-link m-submit m-save ${saved === 1 ? "m-saved" : ""} ${loadingButton === 1 ? "m-loading" : ""}`} disabled={`${activeEditor === 1 ? "" : "disabled"}`} onClick={event => handleClick()}><span><small>{saved === 1 ? "Saved" : "Save"}</small></span></button>
                <button className={`m-link ${activeEditor === 1 ? "m-hidden" : ""}`} onClick={event => newNote(1)}><span></span></button>
                <button className={`m-link m-close ${activeEditor === 0 ? "m-hidden" : ""}`} onClick={event => setActiveEditor(0)}><span></span></button>
            </div>
        </div>
        <div id="content">
            <div className="b-notes">
              <Scrollbars 
                style={{ width: '100%', height: '100%' }}
                hideTracksWhenNotNeeded={true}
                autoHide
                autoHideTimeout={250}
              >
                <div className="notes__wrp">
            
                    {loadingNotes === 1 ? (
                      <>
                        <div className="b-note m-nothing m-loading"></div>
                        <div className="b-note m-nothing m-loading"></div>
                        <div className="b-note m-nothing m-loading"></div>
                        <div className="b-note m-nothing m-loading"></div>
                        <div className="b-note m-nothing m-loading"></div>
                      </>
                    ) : (

                      notes && notes.length ? (

                        notes.map(note=>(
                          <div className={`b-note ${activeNote === note.id ? "m-active" : ""}`} key={note.id} onClick={ event => getNote(note.id) }>
                              <h4>{getText(note.title, 22)}</h4>
                              <div className="note__bottom">
                                <p>{getText(note.text, 30)}</p>
                                <span>{moment(note.date).fromNow()}</span>
                              </div>
                          </div>
                        ))
      
                      ) : (
                        <div className="b-note m-nothing"><span style={{color: "#fff"}}>Nothing found</span></div>
                      )

                    )}

                </div>
              </Scrollbars>
            </div>
            <div className={`b-content ${activeEditor === 1 ? "m-active" : ""}`}>
                <div className={`content__title ${loadingNote === 1 ? "m-loading" : ""}`}>
                    <input type="text" placeholder="New title" autoComplete="off" value={ title } onChange={(e) => setTitle(e.target.value)} onFocus={onFocus} />
                    <div className="b-title_buttons">
                        {Object.keys(note).length !== 0 && note.category_id !== 3 ? (
                          <button className="m-remove" onClick={(e) => deleteNote(e)}></button>
                        ) : (
                          undefined
                        )}

                        {category === 2 ? (
                          <button className={`m-important ${category === 2 ? "m-active" : ""}`} onClick={(e) => setCategory(1)} onFocus={onFocus}></button>
                        ) : (
                          <button className={`m-important ${category === 2 ? "m-active" : ""}`} onClick={(e) => setCategory(2)} onFocus={onFocus}></button>
                        )}

                    </div>
                </div>
                <div className={`b-editor ${loadingNote === 1 ? "m-loading" : ""}`}>

                  <Editor
                    apiKey='l53il5uv92yccx4zkkmhpbny40ephtbsk5jub1ph8i1c3q9n'
                    onInit={(_evt, editor) => editorRef.current = editor}
                    // initialValue={text}
                    init={{
                      height: '100%',
                      menubar: false,
                      statusbar: false,
                      toolbar_mode: 'sliding',
                      plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'media', 'table', 'code', 'wordcount', 'codesample', 'fullscreen'
                      ],
                      toolbar: 'undo redo | ' +
                      'bold italic underline strikethrough | forecolor backcolor | bullist numlist | fontsize blocks copyButton removeformat | alignleft aligncenter ' +
                      'alignright alignjustify | outdent indent | codesample table hr | ' +
                      'fullscreen',
                      // content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:16px } .mce-content-body { background: #2d2f37; color: #fff; } .tox-tinymce { border-color: #2d2f37; }',
                      content_css: "/editor.css?id=c98517e3c91b213afbe767aff1dae420",
                      // newline_behavior: 'block',
                      font_size_formats: "8px 10px 12px 14px 16px 18px 24px 36px",
                      convert_unsafe_embeds: false,

                      setup: editor => {
                        editor.ui.registry.addButton('copyButton', {
                          icon: 'copy',
                          onAction: () => {

                            const selectedText = editor.selection.getContent({ format: 'html' });

                            if (selectedText) {
                              editor.selection.setContent(`<button class="copy" onclick="parent.copyToClipboard(event)">${selectedText}</button>`);
                            }
                            
                          }
                        });
                      },

                      extended_valid_elements: 'button[class|onclick]', // Allow the onclick attribute on button elements
                      // valid_elements: '*[*]' // (Optional) Allow all elements and attributes

                      formats: {
                        removeformat: [
                          {
                            selector: 'b,strong,em,i,font,u,strike,s,sub,sup,dfn,code,samp,kbd,var,cite,mark,q,del,ins,small,button',
                            remove: 'all',
                            split: true,
                            block_expand: true,
                            expand: false,
                            deep: true
                          },
                          { selector: 'span', attributes: ['style', 'class'], remove: 'empty', split: true, expand: false, deep: true },
                          { selector: '*', attributes: ['style', 'class'], split: false, expand: false, deep: true }
                        ]
                      }

                    }}
                    // value={text}
                    // onChange={setText}
                    // outputFormat='text'
                    // onEditorChange={(e) => setText(e)}
                    // onChange={(e) => setText(e.level.content)}
                    // onKeyDown={(e) => setText(e)}

                    initialValue={initialText}
                    outputFormat='text'
                    onEditorChange={(newText) => setText(newText)}


                    onFocus={onFocus}
                  />

                  {/* <ReactQuill
                    className="editor"
                    theme="snow"
                    modules={modules}
                    value={text}
                    onChange={setText}
                    onFocus={onFocus}
                  /> */}

                </div>
                <div className="content__buttons">
                  <button className={`m-link m-submit m-save ${saved === 1 ? "m-saved" : ""} ${loadingButton === 1 ? "m-loading" : ""}`}  onClick={event => handleClick()}>{saved === 1 ? "Saved" : "Save"}</button>
                  {/* <button className="m-link m-close" onClick={event => setActiveEditor(0)}>Close</button> */}
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default Home
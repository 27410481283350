import axios from "axios";
import { createContext, useEffect, useState } from "react";

const { proxy } = require('../../package.json')

axios.defaults.baseURL = proxy

export const AuthContext = createContext();

export const AuthContexProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(
    sessionStorage.getItem("user_token") || null
    // localStorage.getItem("user_token") || null
  );

  const login = async (inputs) => {
    const res = await axios.post("/auth/login", inputs);
    setCurrentUser(res.data.token);
  };

  useEffect(() => {
    sessionStorage.setItem("user_token", currentUser);
    // localStorage.setItem("user_token", currentUser);
  }, [currentUser]);

  return (
    <AuthContext.Provider value={{ currentUser, login }}>
      {children}
    </AuthContext.Provider>
  );
  
};

import React, { useContext, useState } from 'react'

import { AuthContext } from '../context/authContext.js';
import Locked from "../img/locked.svg";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Login = ({ setToken }) => {

  const [err, setError] = useState(null);
  const [loadingButton, setLoadingButton] = useState(0);

  const { login } = useContext(AuthContext)

  const [inputValue, setInputValue] = useState('');
  // const [clipboardData, setClipboardData] = useState('');

  // useEffect(() => {
  //   const interval = setInterval(checkClipboard, 1000); // Check clipboard every 1 second
  //   return () => clearInterval(interval);
  // }, [clipboardData]);

  // const checkClipboard = async () => {
  //   try {
  //     const text = await navigator.clipboard.readText();
  //     if (text && text !== clipboardData && isValidCode(text)) {
  //       setClipboardData(text);
  //       setInputValue(text);
  //       handleSubmit(text);
  //     }
  //   } catch (err) {}
  // };

  const isValidCode = (text) => {
    const regex = /^\d{6}$/;
    return regex.test(text);
  };

  const handleSubmit = async (text) => {

    setLoadingButton(1)

    try {
      await login({
        password: text,
      })
    } catch (err) {
      setError(err.response.data)



      toast.error(err.response.data);

    }

    setLoadingButton(0)

  };

  const handleKeypress = e => { 
    if (e.key === 'Enter') { handleSubmit(inputValue); } 
  };

  const handleChange = (e) => {

    const re = /^[0-9\b]+$/;

    if (e.target.value === '' || re.test(e.target.value)) {

      setInputValue(e.target.value)

      if (isValidCode(e.target.value)) {
        handleSubmit(e.target.value);
      }

    }

  };

  return (
    <>
    <ToastContainer
    position="bottom-right"
    autoClose={1500}
    hideProgressBar={false}
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss
    draggable={false}
    pauseOnHover
    theme="dark"
    />
    <div className='auth'>
      <div className='b-login'>
        <img src={Locked} alt="" />
        <input required type="text" placeholder='xxxxxx' maxLength="6" name='password' inputMode="numeric" className={`${err ? "m-error" : ""}`} onKeyPress={(e) => handleKeypress(e)} value={inputValue} onChange={(e) => handleChange(e)} autoComplete="off" />
        <button type="button" className={`m-link m-submit ${loadingButton === 1 ? "m-loading" : ""}`} onClick={(e) => handleSubmit(inputValue)}>Sign In</button>
      </div>
    </div>
    </>
  )
}

export default Login
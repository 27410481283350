import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
} from "react-router-dom";

import Login from "./pages/Login"
import Home from "./pages/Home"

import 'bootstrap/dist/css/bootstrap.css';
import "./style.scss"
import { useContext } from "react";
import { AuthContext } from "./context/authContext";

const Layout = ()=>{
  return (
    <>
      <Outlet/>
    </>
  )
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout/>,
    children: [
      {
        path: "/",
        element: <Home/>,
      }
    ]
  }
]);

function App() {

  const { currentUser } = useContext(AuthContext)

  try {
    if(!currentUser || currentUser === "null") {
      return <Login />
    }
  } catch (err) {
    return <Login />
  }

  return (
    <div id="wrapper">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
